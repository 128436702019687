import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Container from 'components/Container';





// import p1 from 'assests/Photos/Achievements/2024/Class12/1.jpg';
// import p2 from 'assests/Photos/Achievements/2024/Class12/2.jpg';
// import p3 from 'assests/Photos/Achievements/2024/Class12/3.jpg';
// import p4 from 'assests/Photos/Achievements/2024/Class12/4.jpg';
// import p5 from 'assests/Photos/Achievements/2024/Class12/5.jpg';





const Class12CentumWinners2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/2024/Class12/1.webp`;
  const p2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/2024/Class12/2.webp`;
  const p3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/2024/Class12/3.webp`;
  const p4 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/2024/Class12/4.webp`;
  const p5 = `${config.base_image_url}/home/home-static/static-images/all-static-images/Achievements/2024/Class12/5.webp`;
  


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source: p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source: p5,
      rows: 1,
      cols: 1,
    },
  
  ];

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} marginBottom={2}>
        {/* <Button
          color="primary"
          size="large"
          endIcon={
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          }
          onClick={() => openLightbox(0)}
        >
          Open the gallery
        </Button> */}
          <Container
      
      >
        <Box>
          <Box >
            <Typography
              variant="h2"
              align={'center'}
           
            >
             Class 12 Centum Winners
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h4"
              align={'center'}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              
            </Typography>
          </Box>
        </Box>
      </Container>
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
        
      )}
    </Box>
  );
};

export default Class12CentumWinners2024;