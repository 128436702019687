import React from 'react';

import Class12CentumWinners2024  from 'views/Class12CentumWinners2024';

const ClassAssembly11CPage = () => {
    return <Class12CentumWinners2024 />;
  };
  
  export default ClassAssembly11CPage;

  